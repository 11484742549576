exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-qr-booth-banner-tsx": () => import("./../../../src/pages/qr/booth-banner.tsx" /* webpackChunkName: "component---src-pages-qr-booth-banner-tsx" */),
  "component---src-pages-qr-press-summer-2023-card-tsx": () => import("./../../../src/pages/qr/press-summer-2023-card.tsx" /* webpackChunkName: "component---src-pages-qr-press-summer-2023-card-tsx" */),
  "component---src-pages-qr-summer-2023-card-tsx": () => import("./../../../src/pages/qr/summer-2023-card.tsx" /* webpackChunkName: "component---src-pages-qr-summer-2023-card-tsx" */),
  "component---src-pages-subscribe-tsx": () => import("./../../../src/pages/subscribe.tsx" /* webpackChunkName: "component---src-pages-subscribe-tsx" */),
  "component---src-pages-wallpapers-tsx": () => import("./../../../src/pages/wallpapers.tsx" /* webpackChunkName: "component---src-pages-wallpapers-tsx" */)
}

